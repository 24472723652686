<!-- innogen.io -->
<template>
    <div>
        <div :class="['navbar-area navbar-area-two', {'is-sticky': isSticky}]">
            <div class="bionix-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img src="../../assets/images/default-monochrome.png" class="black-logo" style="max-width: 50%" alt="logo">
                            <img src="../../assets/images/default-monochrome-white.png" class="white-logo" style="max-width: 50%" alt="logo">
                        </router-link>

                        <div 
                            class="navbar-toggler"
                            @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ 'active': button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ toggler: active }">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Home <i class="fas"></i>
                                    </a>
                                </li>
                                    
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Services <i class="fas"></i>
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        About us <i class="fas"></i>
                                    </a>
                                </li>
             

                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link">Contact</router-link>
                                </li>
                            </ul>
                        </div>

                        <!-- <div class="others-options">
                            <div class="cart-items">
                                <router-link to="/cart">
                                    <i class="fas fa-shopping-cart"></i>
                                    <span>{{shoppingCart.length}}</span>
                                </router-link>
                            </div>

                            <div class="option-item">
                                <div class="search-box" v-on:click="isSearchMethod(isSearch)">
                                    <i class="search-btn flaticon-search"></i>
                                </div>
                            </div>

                            <div class="search-overlay" v-if="isSearch">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="search-overlay-layer"></div>
                                        <div class="search-overlay-layer"></div>
                                        <div class="search-overlay-layer"></div>

                                        <div class="search-overlay-close" v-on:click="isSearchMethod(isSearch)">
                                            <span class="search-overlay-close-line"></span>
                                            <span class="search-overlay-close-line"></span>
                                        </div>

                                        <div class="search-overlay-form">
                                            <form>
                                                <input type="text" class="input-search" placeholder="Search here...">
                                                <button type="submit"><i class="flaticon-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div 
                                class="burger-menu"
                                v-on:click="isMenuMethod(isMenu)"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div> -->
                    </nav>
                </div>
            </div>
        </div>

        <div 
            class="sidebar-modal"
            v-if="isMenu"
        >
            <div class="sidebar-modal-inner">
                <button 
                    type="button" 
                    class="close-btn"
                    v-on:click="isMenuMethod(isMenu)"
                >×</button>
                <div class="sidebar-about-area">
                    <div class="title">
                        <h2>About Us</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>
                <!-- <div class="sidebar-instagram-feed">
                    <h2>Instagram</h2>

                    <ul>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-1.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-2.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-3.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-4.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-5.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-6.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-7.jpg" alt="image">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="../../assets/images/instagram/instagram-8.jpg" alt="image">
                            </a>
                        </li>
                    </ul>
                </div> -->
                <div class="sidebar-contact-area">
                    <div class="contact-info">
                        <div class="contact-info-content">
                            <h2>
                                <a href="mailto:info@innogen.io">info @ innogen.io</a>
                            </h2>

                            <ul class="social">
                                <li>
                                    <a href="https://twitter.com/" target="_blank">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/" target="_blank">
                                        <i class="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarStyleTwo',
    data(){
        return {
            isSticky: false,
            isSearch: false,
            isMenu: false,
            active: false,
            button_active_state: false,
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        }
    },
    methods: {
        isSearchMethod(isSearch){
            console.log(this.isSearch)
            return this.isSearch = !isSearch
        },
        isMenuMethod(isMenu){
            return this.isMenu = !isMenu
        }
    }
}
</script>