<!-- innogen.io -->
<template>
    <div>
        <div class="machine-learning-slider">
            <carousel :autoplay="5000" :settings='settings' :wrap-around="true">
                <slide v-for="slide in carouselItems" :key="slide.id">
                    <div :class="['machine-learning-banner', slide.class]">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7">
                                            <div class="banner-content">
                                                <h1>{{ slide.heading }}</h1>
                                                <p>{{ slide.desc }}</p>

                                                <!-- <div class="banner-btn">
                                                    <router-link to="/contact" class="default-btn mr-4">
                                                        Get Started <span></span>
                                                    </router-link>
                        
                                                    <router-link to="/about-two" class="default-btn-two">
                                                        Try It Free <span></span>
                                                    </router-link>
                                                </div> -->
                                            </div>
                                        </div>

                                        <!-- <div class="col-lg-5">
                                            <div class="ml-video">
                                                <div class="solution-video">
                                                    <div 
                                                        class="video-btn popup-youtube"
                                                        v-on:click="isPopupMethod(isPopup)"
                                                        style="cursor: pointer"
                                                    >
                                                        <i class="flaticon-play-button"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
                        <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
                        <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
                        <div class="shape-img5"><img src="../../assets/images/shape/shape-5.png" alt="image"></div>
                        <div class="shape-img6"><img src="../../assets/images/shape/shape-6.png" alt="image"></div>
                        <div class="shape-img7"><img src="../../assets/images/shape/shape-7.png" alt="image"></div>
                        <div class="shape-img8"><img src="../../assets/images/shape/shape-8.png" alt="image"></div>
                        <div class="shape-img9"><img src="../../assets/images/shape/shape-9.png" alt="image"></div>
                        <div class="shape-img10"><img src="../../assets/images/shape/shape-10.png" alt="image"></div>
                    </div>
                </slide>

                <template #addons>
                    <Navigation />
                    <Pagination />
                </template>
            </carousel>
        </div>

        <!-- <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'MainBannerSeven',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'ml-bg1',
                heading: 'Empowering Enterprises with Smart Software',
                desc: 'Our bespoke software solutions are meticulously designed to integrate with your unique business processes, enhancing productivity and propelling you towards your strategic goals with the precision of AI.',
            },
            {
                id: 2,
                class: 'ml-bg2',
                heading: 'Pioneering the Future with Generative AI',
                desc: 'Innogen.io harnesses the transformative power of generative AI to craft dynamic solutions, drive innovation, and create a seamless tech-human interface for businesses seeking growth and efficiency.',
            },
            {
                id: 3,
                class: 'ml-bg3',
                heading: 'Innovative Partnerships for Tech-Driven Success',
                desc: 'Collaborate with us to leverage cutting-edge technologies that redefine customer engagement, operational agility, and market adaptability, setting new benchmarks in your industry.',
            },
        ],
        isPopup: false,
    }),
    methods: {
        isPopupMethod(isPopup) {
            return this.isPopup = !isPopup
        },
    },
})
</script>