<!-- innogen.io -->
<template>
    <div>
        <NavbarStyleTwo />
        <MainBannerSeven />
        <OurFeaturedSolutions />
        <EngagingNew />
        <!-- <EngagingNewAudiences /> -->
        <!-- <OurProfessionalsServices /> -->
        <!-- <ProudProjectsThatMakes />
        <FunFacts />
        <SomeLovelyFeedbackTwo />
        <OurPricingPlan />
        <OurExpertTeam />
        <FrequentlyAskedQuestions />
        <OurLovingClients /> -->
        <LetsGetToWork />
        <!-- <StartYourFreeTrial />
        <OurRecentStory /> -->
        <ContactContent />
        <FooterStyleTwo />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import MainBannerSeven from '../HomeEight/MainBannerSeven'
import OurFeaturedSolutions from '../HomeEight/OurFeaturedSolutions'
import EngagingNew from '../HomeEight/EngagingNew'
//import EngagingNewAudiences from '../HomeEight/EngagingNewAudiences'
//import OurProfessionalsServices from '../HomeEight/OurProfessionalsServices'
// import ProudProjectsThatMakes from '../HomeEight/ProudProjectsThatMakes'
// import FunFacts from '../Common/FunFacts'
// import SomeLovelyFeedbackTwo from '../Common/SomeLovelyFeedbackTwo'
// import OurPricingPlan from '../HomeEight/OurPricingPlan'
// import OurExpertTeam from '../Common/OurExpertTeam'
// import FrequentlyAskedQuestions from '../Common/FrequentlyAskedQuestions'
// import OurLovingClients from '../Common/OurLovingClients'
import LetsGetToWork from '../Common/LetsGetToWork'
//import StartYourFreeTrial from '../HomeEight/StartYourFreeTrial'
//import OurRecentStory from '../HomeEight/OurRecentStory'
import ContactContent from '../Contact/ContactContent'
//import Footer from '../Layout/Footer'
import FooterStyleTwo from '../Layout/FooterStyleTwo'


export default {
    components: { 
        NavbarStyleTwo,
        MainBannerSeven,
        OurFeaturedSolutions,
        EngagingNew,
        //EngagingNewAudiences,
        //OurProfessionalsServices,
        
        // ProudProjectsThatMakes,
        // FunFacts,
        // SomeLovelyFeedbackTwo,
        // OurPricingPlan,
        // OurExpertTeam,
        // FrequentlyAskedQuestions,
        // OurLovingClients,
        LetsGetToWork,
        //StartYourFreeTrial,
        //OurRecentStory,
        ContactContent,
        FooterStyleTwo,
    }
}
</script>