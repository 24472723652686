<template>
    <div class="footer-wrap-area pt-100 pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-card">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/default-monochrome-white.png" style="max-width: 50%" alt="logo">
                        </router-link>
                        <ul class="social-links">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
            
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-card">
                        <h3>Contact Info</h3>

                        <ul class="contact-links">
                            <li><span>Address:</span> Porto, Portugal</li>
                            <li><span>Website:</span> <a href="https://innogen.io/" target="_blank">InnoGen.io</a></li>
                            <li><span>Email:</span> <a href="mailto:info@innogen.io">info@innogen.io</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-wrap-line">
            <img src="../../assets/images/cryptocurrency-home/footer/wrap-line.png" alt="image">
        </div>
       
    </div>
</template>

<script>
export default {
    name: 'FooterStyleTwo'
}
</script>