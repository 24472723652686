<template>
    <div class="pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">What We Offer</span>
                <h2>Empowering Your Business</h2>
                <p>At the heart of innovation, Innogen.io brings AI-driven solutions that redefine efficiency, creativity, and intelligence in your business.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bxs-analyse'></i>
                        <h3>
                            Next-Gen AI Chatbots
                        </h3>
                        <p>Revolutionize your business with our advanced generative AI chatbots. Powered by RAG and fine-tuned models, these chatbots offer personalized, intelligent responses, elevating services to new heights.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bx-shape-circle'></i>
                        <h3>
                            Intelligent Content Generation
                        </h3>
                        <p>Unleash creativity with AI that writes, designs, and composes. Our generative AI models provide innovative content solutions, from automated writing to dynamic graphic design, enhancing your creative output.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bx-layer'></i>
                        <h3>
                            AI-Powered Analytics
                        </h3>
                        <p>Transform data into actionable insights with our AI-powered analytics. Make informed decisions with predictive models and deep learning, driving business growth and operational excellence.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bx-badge-check'></i>
                        <h3>
                            IoT Connectivity Solutions
                        </h3>
                        <p>Bridge the physical and digital worlds with our IoT connectivity solutions. Experience seamless integration of devices and systems, offering real-time data, improved efficiency, and enhanced control.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bx-badge-check'></i>
                        <h3>
                            Bespoke Software Solutions
                        </h3>
                        <p>Tailored software that fits your unique needs. Our team specializes in developing custom applications that are both intelligent and user-friendly, ensuring your software solution is as smart as your business.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="featured-solution-card">
                        <i class='bx bx-badge-check'></i>
                        <h3>
                            Efficiency Through Automation
                        </h3>
                        <p>Streamline your operations with our AI-enhanced automation solutions. From process optimization to workflow automation, our technology is designed to increase productivity and reduce costs.</p>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        name: 'OurFeaturedSolutions'
    }
</script>