<template>
    <div class="choose-area-two pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="choose-content">
                        <div class="section-title text-left">
                            <span class="sub-title">About Us</span>
                            <h2>Innovating at the Intersection of AI and Connectivity</h2>

                            <p>Welcome to InnoGen.ai, where the future of technology is being written today. At the heart of our mission is a commitment to pioneering advancements in generative AI, connected IoT ecosystems, and intelligent software solutions. We are a team of dreamers, innovators, and tech enthusiasts, united by a passion for creating technologies that not only solve today's challenges but also anticipate tomorrow's needs.</p>
                            
                            <p>Our journey began with a vision to harness the potential of generative AI, such as RAG and fine-tuned models, to transform how businesses interact with their customers. We saw the opportunity to blend this with the burgeoning field of IoT, creating connected and intelligent environments that redefine efficiency and productivity.</p>

                            <p>Our expertise doesn't stop there. We excel in crafting bespoke software solutions, tailored to the unique needs of each business we partner with. From automating mundane tasks to developing complex, scalable systems, our software solutions are designed to be as dynamic and adaptable as the businesses they serve.</p>

                            <p>At InnoGen.ai, we believe in the power of technology to change the world. Every line of code we write, every AI model we train, and every IoT solution we deploy is a step towards a smarter, more connected future. Join us on this exciting journey as we continue to push the boundaries of what's possible in the realm of AI and IoT.</p>
                        </div>

<!--                         <div class="choose-btn">
                            <router-link class="default-btn" to="/about-one">
                                Discover More 
                                <span></span>
                            </router-link>
                        </div> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="choose-image">
                        <img src="../../assets/images/machine-learning/about-3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'engagingnew'
    }
</script>